






import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({})
export default class ProgressLine extends Vue {
  @Prop({
    type: Number,
    required: true
  })
  private progress!: number

  @Prop({
    required: true,
    type: String
  })
  private totalStep!: string

  private img = require('../assets/images/progress-line/progress-bar-progress.svg')
  private rect = ''
  private lineWidth = 0

  mounted (): void {
    const elClip: HTMLElement | null = document.querySelector('.clip')
    this.lineWidth = elClip ? elClip.offsetWidth : 100
    this.setClipPass(this.progress)
  }

  private setClipPass (progress: number): void {
    const width = this.lineWidth / (Number(this.totalStep) / +progress)
    if (window.innerWidth > this.$data.mobileWidth) {
      this.rect = `clip:rect(0, ${width}px, 4px, 0);`
    } else {
      this.img = require('../assets/images/progress-line/progress-bar-progress2.svg')
      this.rect = `clip:rect(0, ${width}px, 10px, 0);`
    }
  }

  @Watch('$route')
  onRouteChange ():void {
    this.setClipPass(this.progress)
  }
}
