






















import { Component, Vue } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import ProgressLine from '@/components/ProgressLine.vue'
import Player from '@/components/common/Player.vue'
import Modal from '@/components/common/Modal.vue'
import isOS from '@/helper/detectOs'
@Component({
  components: {
    PageLayout,
    ContentArea,
    PrimaryButton,
    ProgressLine,
    Player,
    Modal
  }
})
export default class Welcome extends Vue {
  private continueButtonPath = ''

  private created (): void {
    if (sessionStorage.getItem('timer/quidel/4') === 'isRun') {
      this.continueButtonPath = 'quidel/4'
    } else if (sessionStorage.getItem('timer/quidel/2') === 'isRun') {
      this.continueButtonPath = '/quidel/2/Screen_2'
    } else {
      this.continueButtonPath = ''
    }
  }

  private mounted (): void {
    if (window.innerWidth < this.$data.mobileWidth && isOS()) window.scrollTo(0, 1)
  }

  get videoUrls (): Array<string> {
    return this.$store.state.video.videoUrls[0]
  }
}
